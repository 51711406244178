<template>
	<div class="px-4 w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<div class="dF">
			<a-icon type="arrow-left" @click="$router.go(-1)" />
		</div>
		<div>
			<a-tabs size="large" default-active-key="1">
				<!-- <div slot="tabBarExtraContent" class="dF aC" style="gap: 20px">
					<div class="dF" style="gap: 5px">
						<span>Print:</span>
						<a @click.prevent="getCSV" href="javascript:;" class="text-primary">
							<a-icon type="printer" />
						</a>
					</div>
					<div class="dF" style="gap: 5px">
						<span>Download:</span>
						<a @click.prevent="getCSV" href="javascript:;" class="text-primary">CSV</a>
					</div>
				</div> -->
				<a-tab-pane key="1">
					<span slot="tab">
						{{ $route.query.name }}
					</span>
					<div v-if="!$route.query.id" class="mt-5" style="text-align: center">
						<h5>No Record Found</h5>
					</div>
					<div v-else-if="loading" style="text-align: center; font-size: 30px">
						<a-icon type="loading" />
					</div>
					<div v-else-if="websiteDetails">
						<div class="card-grid">
							<a-card title="Active visitors"><span class="card-body">
									{{
										!websiteDetails.facts
										? "N/A"
										: websiteDetails.facts
											.activeVisitors
									}}
								</span></a-card>
							<a-card title="Average views"><span class="card-body">
									{{
										!websiteDetails.facts
										? "N/A"
										: websiteDetails.facts.averageViews
											.count
									}}
									Per Day
								</span></a-card>
							<a-card title="Average duration">
								<span class="card-body">{{
									!websiteDetails.facts
									? "N/A"
									: websiteDetails.facts
										.averageDuration.count
								}}
									s
								</span></a-card>
							<a-card title="Views today">
								<span class="card-body">{{
									!websiteDetails.facts
									? "N/A"
									: websiteDetails.facts.viewsToday
								}}
								</span></a-card>
							<a-card title="Views this month">
								<span class="card-body">{{
									!websiteDetails.facts
									? "N/A"
									: websiteDetails.facts.viewsMonth
								}}</span>
							</a-card>
							<a-card title="Views this year"><span class="card-body">
									{{
										!websiteDetails.facts
										? "N/A"
										: websiteDetails.facts.viewsYear
									}}</span>
							</a-card>
						</div>

						<div class="chart-grid">
							<ChartCard :details="viewOverTime" :loading="loading" />
							<ChartCard :details="duration" :loading="loading" />
							<ChartCard :details="pageByView" :loading="loading" />
							<a-card title="Referrers">
								<ul v-if="websiteDetails.statistics" style="padding-left: 0px">
									<li v-for="(
                                            referrer, index
                                        ) in websiteDetails.statistics
                                        			.referrers" :key="index" style="
                                            padding: 5px 0;
                                            font-weight: bold;
                                            font-size: 18px;
                                        ">
										<span style="word-break: break-word">{{
											referrer.value
										}}</span>
									</li>
								</ul>
							</a-card>
							<ChartCard :details="devices" :loading="loading" />
							<ChartCard :details="browsers" :loading="loading" />
						</div>
					</div>
				</a-tab-pane>
			</a-tabs>
		</div>
	</div>
</template>

<script>
import ChartCard from "@/components/charts/chartCard";
import chartData from "./chartData";
import moment from "moment";

export default {
	components: {
		ChartCard,
	},
	data: () => ({
		loading: false,
		error: false,
		websiteDetails: null,
	}),

	computed: {
		viewOverTime() {
			const views =
				(this.websiteDetails.statistics &&
					this.websiteDetails.statistics.views) ||
				[];
			const chartDetails = {};
			views.forEach((view) => {
				const date = moment(view.value).format("ll");

				if (view.count) {
					if (!chartDetails[date]) chartDetails[date] = 0;
					chartDetails[date] += view.count;
					// chartDetails[date] += +(view.count / 1000).toFixed(2);
				}
			});

			return {
				...chartData.viewOverTime(),
				chartDetails: [{ data: chartDetails }],
			};
		},
		duration() {
			const durations =
				(this.websiteDetails.statistics &&
					this.websiteDetails.statistics.durations) ||
				[];
			const chartDetails = {};
			durations.forEach((duration) => {
				const date = moment(duration.value).format("x");

				if (!chartDetails[date]) chartDetails[date] = 0;
				chartDetails[date] += duration.count;
			});

			return {
				...chartData.duration(),
				chartDetails: [{ data: chartDetails }],
			};
		},
		pageByView() {
			const pages =
				(this.websiteDetails.statistics &&
					this.websiteDetails.statistics.pages) ||
				[];
			const pagesData = [];

			pages.forEach((page) => {
				let pageName = "Others";
				if (this.title !== page.value) {
					const titleIndex =
						page.value.indexOf(this.title) + this.title.length;
					const queryStringIndex = page.value.indexOf("?");
					pageName =
						page.value.substring(
							titleIndex,
							queryStringIndex < 0
								? page.value.length
								: queryStringIndex
						) || "Others";
				}

				const index = pagesData.findIndex(
					(item) => item.name === pageName
				);
				if (index === -1) {
					pagesData.push({
						name: pageName,
						value: page.count,
					});
				} else {
					pagesData[index] = {
						...pagesData[index],
						value: pagesData[index].value + page.count,
					};
				}
			});

			return chartData.pageByView(Object.values(pagesData));
		},
		devices() {
			const devices =
				(this.websiteDetails.statistics &&
					this.websiteDetails.statistics.devices) ||
				[];
			const devicesData = [];

			devices.forEach((device) => {
				let deviceName = device.value;

				const index = devicesData.findIndex(
					(item) => item.name === deviceName
				);
				if (index === -1) {
					devicesData.push({
						name: deviceName,
						value: device.count,
					});
				} else {
					devicesData[index] = {
						...devicesData[index],
						value: devicesData[index].value + device.count,
					};
				}
			});
			return chartData.devices(devicesData);
		},
		browsers() {
			const browsers =
				(this.websiteDetails.statistics &&
					this.websiteDetails.statistics.browsers) ||
				[];
			const browsersData = [];

			browsers.forEach((device) => {
				let browserName = device.value;

				const regex = /\d+/g;
				const matches = browserName.match(regex);

				if (matches.length) {
					const versionIndex = browserName.indexOf(matches[0]);
					browserName = browserName.substring(0, versionIndex).trim();
				}

				const index = browsersData.findIndex(
					(item) => item.name === browserName
				);
				if (index === -1) {
					browsersData.push({
						name: browserName,
						value: device.count,
					});
				} else {
					browsersData[index] = {
						...browsersData[index],
						value: browsersData[index].value + device.count,
					};
				}
			});
			return chartData.browsers(browsersData);
		},
		title() {
			return this.websiteDetails.title;
		},
	},

	created() {
		this.fetchWebsiteDetails(this.$route.query.id);
	},

	methods: {
		fetchWebsiteDetails(id) {
			if (id) {
				this.loading = true;
				this.$api
					.post(
						`/daily-reports/${this.$store.state.instance.id}/web`,
						{
							id: id,
						}
					)
					.then(({ data }) => {
						this.websiteDetails = data;
						this.loading = false;
					})
					.catch((error) => {
						console.error(error);
						this.error = true;
						this.loading = false;
					});
			}
		},

		getCSV() {
			// get data
		}
	},
};
</script>

<style lang="scss" scoped>
.card-grid {
	display: grid;
	grid-template-columns: minmax(0, 1fr);
	gap: 20px;

	@media screen and (min-width: 768px) {
		grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
	}
}

.chart-grid {
	display: grid;
	grid-template-columns: minmax(0, 1fr);
	gap: 20px;
	margin-top: 20px;

	@media screen and (min-width: 768px) {
		grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
	}
}

.card-body {
	font-size: 26px;
	font-weight: bold;
	padding-left: 0px;
}

ul li {
	list-style: none;
	font-size: 20px;
}
</style>
